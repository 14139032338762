import React from "react"
import Layout from "../components/Layout/layout"
// import Header from "../components/Header/header"
// import Footer from "../components/Footer/footer"
// import Section from "../components/Section/section"
// import SEO from "../components/seo"
// import Hero from "../components/Hero/hero"
//import i18n from '../components/i18n';

export default function Index() {
  return <Layout></Layout>
}